new Vue({

    el : '#notifications',

    data : {

        notifications : getSharedData('notifications', []),

        notificationKey : getSharedData('notificationKey', ''),

        notificationIds : [],

        driver : 'ajax',

    },

    ready : function() {

        notify.config({pageVisibility: false, autoClose: 4000});

        var vm = this;

        if (typeof this.notifications == 'object') {

            var notificationArray = $.map(this.notifications, function(value, index) {
                return [value];
            });

            this.notifications = notificationArray;
        }

        this.startAndShowNotifications();

    },

    methods : {

        startAndShowNotifications : function() {

            var vm = this;

            this.notifications.forEach(function (val, key) {
                vm.notificationIds.push(val.id);
            });

            if (this.notificationKey == '') {
                console.log('Notification Key is not set');
            }

            setInterval(vm.fetchNotifications, 10000);

        },

        fetchNotifications : function () {

            var vm = this;

            var userData = JSON.stringify({ 'notificationKey' : this.notificationKey });

            $.ajax({
                url: '/api/notifications',
                data: userData,
                method: 'POST',
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
            }).success(function (data) {
                vm.notifications = data;
            }).error(function (data) {
                console.log('Error from Ajax', data);
            })

        }

    },

    watch : {



        'notifications' : function (newVal, oldVal) {

            var update = false;

            var updateId = 0;

            var msg = '';

            var vm = this;

            newVal.forEach(function(val, key) {
                if (vm.notificationIds.indexOf(val.id) < 0) {
                    update = true;
                    updateId = val.id;
                    msg = val.body;
                }
            });

            if (update) {

                if (notify.permissionLevel() == notify.PERMISSION_GRANTED) {
                    notify.createNotification('Notification', {
                        body  : msg,
                        icon  : {
                            "x16": '/img/alert16.ico',
                            "x32": '/img/alert32.ico',
                            "x128" : '/img/alert128.ico'
                        },
                        tag   : updateId
                    });
                } 
    
                $.iGrowl({
                    icon: "igrowl fa fa-bell",
                    type: "info",
                    title: "Notification",
                    message: msg,
                    animShow: "fadeIn",
                    animHide: "fadeOut",
                    delay: 3000
                });
            }

            vm.notificationIds = [];

            newVal.forEach(function(val, key) {
               vm.notificationIds.push(val.id);
            });

        }

    }

});